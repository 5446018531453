import {
  ExecutionsService,
  ChecksService,
  CheckResultCommandOutput,
} from 'shared/api/client';
import { mapCheckResults } from '../shared/utils';
import { Params } from 'react-router-dom';
import { normalizeArray } from 'shared/utils';
import { CheckResult, ExecutedCommand, ReportModel } from './models';

export function fetchData(params: Params<string>) {
  const { executionId } = params;

  if (executionId) {
    const isLast = (executionId || '')?.toLowerCase() === 'last';
    const request = isLast
      ? ExecutionsService.getLastExecutionApiV1ExecutionsLastGet()
      : ExecutionsService.getExecutionApiV1ExecutionsExecutionIdGet({
          executionId: executionId,
        });

    return Promise.all([
      request,
      ChecksService.listChecksApiV1ChecksGet(),
    ]).then(([executionResponse, listChecksResponse]) => {
      const checkList = normalizeArray({
        array: listChecksResponse.checks || [],
        key: 'id',
      });
      const mapped = mapCheckResults(
        executionResponse?.check_results || [],
        checkList,
      );

      return {
        id: executionResponse?.id,
        createdAt: executionResponse?.created_at,
        finishedAt: executionResponse?.finished_at,
        suiteId: executionResponse?.suite_id,
        status: executionResponse?.status,
        items: normalizeArray({ array: mapped, key: 'checkResultId' }),
      } as ReportModel;
    });
  }
  return Promise.reject(`The "id" parameter is missing`);
}

export function convertRecord(
  input: Record<string, CheckResultCommandOutput>,
): Record<string, ExecutedCommand> {
  const output: Record<string, ExecutedCommand> = {};

  for (const key in input) {
    if (input.hasOwnProperty(key)) {
      const checkResult: CheckResultCommandOutput = input[key];
      const executedCommand: ExecutedCommand = {
        id: checkResult?.id,
        recommendationId: checkResult?.recommendation_id,
        output: checkResult?.output,
      };
      output[key] = executedCommand;
    }
  }

  return output;
}

export function fetchCheckResultDetails(params: Params<string>) {
  const { executionId, checkResultId } = params;

  if (executionId && checkResultId) {
    return Promise.all([
      ChecksService.getCheckResultApiV1ChecksCheckResultIdGet({
        checkResultId: checkResultId,
      }),
    ]).then(([checkResult]) => {
      return {
        id: checkResult?.id,
        objectName: checkResult?.object_name,
        checkTitle: checkResult?.check_title,
        severity: checkResult?.severity,
        status: checkResult?.status,
        namespace: checkResult?.object_meta['resource_namespace'] || '',
        explanation: checkResult?.explanation,
        executedCommands: convertRecord(checkResult?.executed_commands || {}),
        recommendations: checkResult?.recommendations,
        resource_type:checkResult?.object_meta.resource_type
      } as CheckResult;
    });
  }
  return Promise.reject(`The "id" parameter is missing`);
}
