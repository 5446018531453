import { RouteObject } from 'react-router-dom';
import { Page as PageModel, RoutePageData } from '../models';

export class RoutePage implements PageModel {
  private _route: RouteObject;
  private _title?: string;
  public description?: string;
  public children: RoutePage[] = [];
  private _outlet = false;
  private _wrapToPage = true;
  private _pageStyle?: 'summary' | 'details';
  private _showHomePageInBreadcrumbs?: boolean;
  private _headerRightTemplate: React.ReactNode;

  constructor({
    title,
    description,
    route,
    headerRightTemplate,
    wrapToPage,
    pageStyle,
    showHomePageInBreadcrumbs,
    outlet,
    children,
  }: RoutePageData) {
    this._title = title;
    this._route = route;
    this.description = description;
    this.children = children || [];
    this._outlet = outlet || false;
    this._headerRightTemplate = headerRightTemplate;
    if (wrapToPage === false) {
      this._wrapToPage = wrapToPage;
    }
    this._pageStyle = pageStyle;
    this._showHomePageInBreadcrumbs = showHomePageInBreadcrumbs;
  }

  get isOutlet() {
    return this._outlet;
  }

  get wrapToPage() {
    return this._wrapToPage;
  }

  get pageStyle() {
    return this._pageStyle;
  }

  get showHomePageInBreadcrumbs() {
    return this._showHomePageInBreadcrumbs;
  }

  get title() {
    return this._title || '';
  }

  set title(value: string) {
    this._title = value;
  }

  get route() {
    return this._route;
  }

  get headerRightTemplate() {
    return this._headerRightTemplate;
  }

  addChild(child: RoutePage) {
    this.children.push(child);
  }

  toRouting(): RouteObject {
    return {
      ...this._route,
      children:
        this.children.length > 0
          ? this.children.map((x) => x.toRouting())
          : undefined,
      handle: {
        isIndex: this._route.index,
        title: this.title,
        headerRightTemplate: this.headerRightTemplate,
        description: this.description,
        crumb: () => {
          return this.title;
        },
      },
    } as RouteObject;
  }
}
