import React from 'react';
import styles from './styles.module.scss';
import 'primeicons/primeicons.css';

interface StatusIndicatorProps {
  color: string;
}

const StatusIndicator: React.FC<StatusIndicatorProps> = ({
  color,
}) => {
  return (
    <div className={styles.statusContainer}>
      <i
        className="pi pi-circle-fill"
        style={{ color, fontSize: '0.625rem' }}
      ></i>
    </div>
  );
};

export default StatusIndicator;
