import { Severity, SeverityProps } from 'components';
import { ExplanationColumnCell } from '../../current-report/explanation-column-cell';
import React from 'react';
import { ReportTableDataItem } from '../models';
import { DataGridColumns } from 'components/data-grid';
import { severityOrder } from './constants';
import { ColumnBodyOptions } from 'primereact/column';

export const createReportColumns = (
  items: ReportTableDataItem[],
): DataGridColumns<ReportTableDataItem> => [
  {
    options: {
      header: 'Sr No',
      field: 'srno',
      body: (row: ReportTableDataItem, options: ColumnBodyOptions) => {
        return options.rowIndex + 1; // Adjust this based on how rowIndex is accessed
      },
      style: {
        width: '5%', // Adjust as necessary
        minWidth: '5rem',
      },
    },
  },
  {
    filter: true,
    options: {
      header: 'Object',
      field: 'object',
      style: {
        width: '20%',
      },
      sortable: true,
      filterField: 'object',
    },
  },
  {
    filter: true,
    options: {
      header: 'Resource Type',
      field: 'resourceType',
      sortable: true,
      filterField: 'resourceType',
      style: {
        width: '10%',
      },
    },
  },
  {
    filter: true,
    options: {
      header: 'Namespace',
      field: 'namespace',
      sortable: true,
      filterField: 'namespace',
      style: {
        width: '10%',
      },
    },
  },
  {
    filter: true,
    options: {
      header: 'Check',
      field: 'check',
      sortable: true,
      filterField: 'check',
      style: {
        width: '20%',
      },
    },
  },
  {
    filter: true,
    options: {
      header: 'Parent',
      field: 'parent',
      sortable: true,
      filterField: 'parent',
      body: (row: ReportTableDataItem) => {
        const isTargetRow =
          row.object === 'store-front' &&
          row.resourceType === 'deployment' &&
          row.namespace === 'store-webapp';
        // Store the index of the first matching row in a variable
        const firstMatchingRowIndex =
          items.findIndex(
            (item) =>
              item.object === 'store-front' &&
              item.resourceType === 'deployment' &&
              item.namespace === 'store-webapp',
          ) + 1;

        // If the current row is the target row, return the rowIndex
        if (isTargetRow) {
          return 'N/A'; // Or return the rowIndex if you want to show the row number of the target row instead
        }

        // If the row is in the "store-webapp" namespace, return the first matching row index
        if (row.namespace === 'store-webapp') {
          return firstMatchingRowIndex > 0 ? firstMatchingRowIndex : ''; // If no match found, return empty
        }

        // Otherwise, return the actual value of the parent field
        return row.parent || ''; // Fallback to an empty string if parent is undefined
      },

      style: {
        width: '20%',
      },
    },
  },
  // {
  //   filter: true,
  //   options: {
  //     header: 'Result',
  //     field: 'result',
  //     sortable: true,
  //     filterField: 'result',
  //     body: (row: ReportTableDataItem) => {
  //       return <ResultStatus type={row?.status}>{row?.result}</ResultStatus>;
  //     },
  //     style: {
  //       width: '8%',
  //       minWidth: '8rem',
  //     },
  //   },
  // },
  {
    filter: true,
    options: {
      header: 'Severity',
      field: 'severity',
      sortable: true,

      filterField: 'severity',
      sortOptionsInFilter: (a, b) => {
        const severityA = typeof a === 'string' ? a : ''; // Ensure a is a string
        const severityB = typeof b === 'string' ? b : ''; // Ensure b is a string

        const severityIndexA = severityOrder.indexOf(severityA);
        const severityIndexB = severityOrder.indexOf(severityB);
        if (severityIndexA < severityIndexB) {
          return -1;
        } else if (severityIndexA > severityIndexB) {
          return 1;
        } else {
          return 0;
        }
      },
      sortFunction: (e) => {
        const value = [...(e.data || [])] as ReportTableDataItem[];
        (value || []).sort((a, b) => {
          const severityIndexA = severityOrder.indexOf(a.severity || '');
          const severityIndexB = severityOrder.indexOf(b.severity || '');
          if (severityIndexA < severityIndexB) {
            return e.order === 1 ? -1 : 1;
          } else if (severityIndexA > severityIndexB) {
            return e.order === 1 ? 1 : -1;
          } else {
            return 0;
          }
        });

        return value;
      },
      body: (row: ReportTableDataItem) => {
        return (
          <Severity
            type={(row?.severity || '')?.toLowerCase() as SeverityProps['type']}
          />
        );
      },
      style: {
        width: '8%',
        minWidth: '8rem',
      },
    },
  },
  {
    options: {
      header: 'Explanation',
      field: 'explanation',
      body: (row: ReportTableDataItem) => {
        return <ExplanationColumnCell row={row} />;
      },
      style: {
        width: '35%',
        minWidth: '12rem',
      },
    },
  },
];
