import { format, parseJSON } from 'date-fns';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'shared/constants';
import { isEmpty } from 'shared/utils';

export function isObjectInArray<T>(arr: T[], obj: T): boolean {
  const objString = JSON.stringify(obj);
  return arr.some((item) => JSON.stringify(item) === objString);
}

export const getDataTime = (str: string) => {
  if (isEmpty(str)) return 'N/A';
  const date = parseJSON(str);
  const formattedDate = format(date, DATE_TIME_FORMAT);
  return formattedDate;
};

export const getDate = (str: string) => {
  if (isEmpty(str)) return 'N/A';
  const date = parseJSON(str);
  const formattedDate = format(date, DATE_FORMAT);
  return formattedDate;
};
