import { Button } from 'primereact/button';
import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { ChecksService } from 'shared/api/client';
import { useDialog } from 'shared/providers/dialog/context';
import { useToast } from 'shared/providers/toast/context';
import { CheckDetailsState } from '../failed-check-details/context';
import { fetchCheckResultDetails } from '../shared/requests';
import { filterCommandsByCondition, mapRecommendations } from '../shared/utils';
import styles from './styles.module.scss';
import { diagnosticCommand1 } from 'shared/constants';
import diagnosticCommandOutput1 from '../../../shared/data/diagnostic_output1.json';

export const ResetRecommendationsModal = () => {
  return (
    <div className={styles.reqRecommendationsModal}>
      <p className={styles.content}>
        Are you sure you want reset recommendations?
      </p>
    </div>
  );
};

interface ResetRecommendationsModalFooterProps {
  executionId?: string;
  checkResultId?: string;
  setData: (state: CheckDetailsState) => void;
  navigate: NavigateFunction;
  setResetLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ResetRecommendationsModalFooter = ({
  executionId,
  checkResultId,
  setResetLoading,
  setData,
}: ResetRecommendationsModalFooterProps) => {
  const { closeDialog } = useDialog();
  const { showApiError } = useToast();

  const onResetHandler = () => {
    closeDialog();

    if (executionId && checkResultId) {
      setResetLoading(true);
      ChecksService.resetRecommendationsApiV1ChecksCheckResultIdResetRecommendationsDelete(
        {
          checkResultId,
        },
      )
        .then((res) => {
          fetchCheckResultDetails({ executionId, checkResultId })
            .then((result) => {
              if (
                result.namespace === 'store-webapp' &&
                result.objectName === 'store-front' &&
                result.resource_type === 'deployment'
              ) {
                // Logic to append the demo command after the third command from the left panel diagnostics when reset
                // Get the first three keys of executedCommands
                const firstThreeKeys = Object.keys(
                  result?.executedCommands,
                ).slice(0, 3);

                // Create a new object containing only the first three commands
                const limitedExecutedCommands = firstThreeKeys.reduce(
                  (acc, key) => {
                    acc[key] = result?.executedCommands[key]; // Type assertion here
                    return acc;
                  },
                  {} as Record<string, any>,
                );

                // Modify the executedCommands object to append the new command with the hardcoded output
                const modifiedExecutedCommands = {
                  ...limitedExecutedCommands,
                  [diagnosticCommand1]: {
                    id: 'demo-command-id',
                    output: JSON.stringify(diagnosticCommandOutput1, null, 2), // Store the output as a string
                    recommendationId: null,
                  },
                };
                setData({
                  details: result,
                  iterations: {
                    'System diagnostics': {
                      id: 'system_diagnostics',
                      isExpanded: true,
                      data: {
                        Summary: {
                          type: 'summary',
                          isExpanded: true,
                          value: result?.explanation,
                        },
                        Diagnostics: {
                          type: 'commands',
                          isExpanded: true,
                          commands:
                            filterCommandsByCondition(
                              modifiedExecutedCommands,
                              (x) => !x.recommendationId,
                            ) || {},
                        },
                      },
                    },
                    ...mapRecommendations(
                      result?.recommendations || [],
                      modifiedExecutedCommands || {},
                    ),
                  },
                });
              } else {
                setData({
                  details: result,
                  iterations: {
                    'System diagnostics': {
                      id: 'system_diagnostics',
                      isExpanded: true,
                      data: {
                        Summary: {
                          type: 'summary',
                          isExpanded: true,
                          value: result?.explanation,
                        },
                        Diagnostics: {
                          type: 'commands',
                          isExpanded: true,
                          commands:
                            filterCommandsByCondition(
                              result?.executedCommands,
                              (x) => !x.recommendationId,
                            ) || {},
                        },
                      },
                    },
                    ...mapRecommendations(
                      result?.recommendations || [],
                      result?.executedCommands || {},
                    ),
                  },
                });
              }
              setResetLoading(false);
            })
            .catch((e) => {
              setResetLoading(false);
              showApiError(e);
            });
        })
        .catch((e) => {
          setResetLoading(false);
          showApiError(e);
        });
    }
  };

  return (
    <div className={styles.reqRecommendationsModalFooter}>
      <Button onClick={onResetHandler}>Reset recommendations</Button>
      <Button onClick={closeDialog} severity="secondary">
        Cancel
      </Button>
    </div>
  );
};
