import React, { useCallback, useLayoutEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import cx from 'classnames';
import styles from './styles.module.scss';
import { TileGroup } from 'pages/reports/current-report/tile-group';
import { Charts } from './charts';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { Field, DataGrid, Icon, LoadingDots, usePageContext } from 'components';
import { ClearAll, FilterTags } from './fiter-tags';
import {
  DataTableFilterMetaData,
  DataTableRowDataArray,
  DataTableStateEvent,
  DataTableValueArray,
} from 'primereact/datatable';
import { ReportModel, ReportTableDataItem, TabOption } from '../shared/models';
import {
  defaultFilters,
  filterTitles,
  getReportTiles,
  chartTabs,
  createReportColumns,
} from '../shared/configurations';
import { ActiveElement, ChartEvent } from 'chart.js';
import { RoutePageData } from 'router/shared/models';
import { SkeletonTemplateReportTable } from './skeleton-templates/skeleton-table';
import {
  useShowMore,
  useLoaderWithLocation,
  useFetchRefreshPage,
  useEffectUpdate,
} from 'hooks';
import { fetchData } from '../shared/requests';
import { getDataTime } from 'components/utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ErrorPage } from 'pages/error-page';
import { useCurrentReport } from './context';
import { ApiError } from 'shared/api/client';

const TIMEOUT_IN_SECONDS = 10;

interface StateProps {
  reportId?: string;
  isLoading?: boolean;
}

export const Report = () => {
  const { state, setState, error404 } = usePageContext<StateProps>();
  const paramsUrl = useParams();
  const location = useLocation();
  const [filteredData, setFilteredData] = useState<ReportTableDataItem[]>([]);

  const {
    filteredCounts,
    setLoadedReport,
    setSort,
    setFilter,
    setFilteredCount,
    getReport,
  } = useCurrentReport();

  const navigate = useNavigate();

  const fetchDataRequest = useCallback(fetchData, [paramsUrl]);
  const fetchDataRequestErrorHandler = useCallback(
    (e: ApiError) => {
      if (e.status === 404) {
        setState((prev) => ({
          ...prev,
          isLoading: false,
        }));
        error404();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paramsUrl],
  );

  const { loadedData, params } = useLoaderWithLocation(
    fetchDataRequest,
    fetchDataRequestErrorHandler,
  );

  const report = getReport(state?.reportId || params?.executionId);

  const filteredCount =
    (filteredCounts || {})[state?.reportId || params?.executionId || ''] || 0;

  const isNeedFetch = (status: ReportModel['status']) =>
    status === 'IN_PROGRESS' || status === 'NEW' || status === undefined;

  useLayoutEffect(() => {
    if (!report) {
      setState((prev) => ({
        ...prev,
        isLoading: true,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffectUpdate(() => {
    setState((prev) => ({
      ...prev,
      isLoading: true,
    }));
  }, [params]);

  useFetchRefreshPage(
    loadedData,
    (x) => isNeedFetch(x?.status),
    (data) => {
      if (data) {
        setState((prev) => ({
          ...prev,
          reportId: data?.id,
          isLoading: false,
        }));
        setLoadedReport(data);
      }
    },
    TIMEOUT_IN_SECONDS,
  );

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (report?.data?.id) {
      setFilter(report?.data?.id, (prev) => ({
        ...prev,
        global: { ...prev.global, value },
      }));
    }
  };

  const {
    data: items,
    resetVisibleRows,
    visibleRows,
    showMoreButton,
  } = useShowMore<ReportTableDataItem>({
    initialData: Object.values(report?.data?.items || {}) || [],
    totalCount: filteredCount || 0,
    rowsPerPage: 15,
  });

  const onFilterHandler = (e: DataTableStateEvent) => {
    if (report?.data?.id) {
      setFilter(report?.data?.id, (prev) => ({ ...prev, ...e?.filters }));
    }
  };

  const onSortHandler = (e: DataTableStateEvent) => {
    if (report?.data?.id) {
      setSort(report?.data?.id, (prev) => ({
        ...prev,
        sortField: e.sortField as keyof ReportTableDataItem,
        sortOrder: e.sortOrder,
      }));
    }
  };

  const onClickChartHandler = (
    _: ChartEvent,
    elements: ActiveElement[],
    tab: TabOption,
  ) => {
    const e = (elements || {})[0];
    const filterName = (tab?.data || [])[e?.index]?.name;
    if (filterName) {
      resetVisibleRows();
      if (report?.data?.id) {
        setFilter(report?.data?.id, (prev) => {
          const newState = { ...prev };
          return {
            ...newState,
            ...defaultFilters,
            [tab.filter]: { ...prev[tab.filter], value: [filterName] },
            result: { ...prev.result, value: ['Fail'] },
          };
        });
      }
    }
  };

  const onClearAllNamespaces = () => {
    if (report?.data?.id) {
      setFilter(report?.data?.id, (prev) => {
        const newState = { ...prev };
        return {
          ...newState,
          namespace: { ...prev?.namespace, value: null },
        };
      });
    }
  };

  const onClearAllFilterHandler = () => {
    if (report?.data?.id) {
      setFilter(report?.data?.id, defaultFilters);
    }
  };

  const onClickTileHandler = () => {
    resetVisibleRows();
  };

  const onClearFilterHandler = (key: string, value: string) => {
    if (report?.data?.id) {
      setFilter(report?.data?.id, (prev) => {
        const newState = { ...prev };
        const currentFilter = newState[key] as DataTableFilterMetaData;
        if (Array.isArray(currentFilter?.value)) {
          const filtered = currentFilter?.value?.filter((s) => s !== value);
          currentFilter.value = filtered;
        } else {
          currentFilter.value = null;
        }
        return { ...newState };
      });
    }
  };

  const onValueDataGridChangeHandler = (
    fd: DataTableRowDataArray<DataTableValueArray[]>,
  ) => {
    // Assuming fd is of type ReportTableDataItem[]
    setFilteredData(fd as ReportTableDataItem[]); // Cast if necessary

    if (report?.data?.id) {
      setFilteredCount(report?.data?.id, (fd || [])?.length || 0);
    }
  };

  const onRowClickHandler = (event: { data: ReportTableDataItem }) => {
    navigate(
      `/reports/${event?.data?.executionId}/details/${event?.data?.checkResultId}`,
      { state: { from: location } },
    );
  };

  const onChangeNamespaces = (e: MultiSelectChangeEvent) => {
    if (report?.data?.id) {
      setFilter(report?.data?.id, (prev) => {
        const newState = { ...prev };
        return {
          ...newState,
          namespace: { ...prev?.namespace, value: e?.value || null },
        };
      });
    }
  };

  const showSkeleton =
    !report ||
    report?.data?.status === 'NEW' ||
    report?.data?.status === 'IN_PROGRESS';

  const titles = getReportTiles({
    reportId: report?.data?.id,
    data: Object.values(report?.data?.items || {}) || [],
    setFilter,
    onClickTileHandler,
  });

  const tabs = chartTabs(Object.values(report?.data?.items || {}) || []);

  if (state?.isError404) {
    return <ErrorPage />;
  }
  const reportColumns = createReportColumns(
    filteredData.length ? filteredData : items || [],
  );
  return (
    <div>
      <div className={cx(styles.tilesAndCharts)}>
        <TileGroup showSkeleton={showSkeleton} tiles={titles} />
        <Charts
          showSkeleton={showSkeleton}
          title={'Top 5 Failed'}
          onClickChart={onClickChartHandler}
          tabs={tabs}
        />
      </div>
      {!showSkeleton && (
        <>
          <div className={cx(styles.searchAndFilter)}>
            <div>
              <span className="p-input-icon-right">
                <InputText
                  value={
                    (report?.filters?.global as DataTableFilterMetaData)
                      ?.value || ''
                  }
                  onChange={onGlobalFilterChange}
                  style={{ width: '27.5rem' }}
                  inputMode="search"
                  placeholder="Search"
                />
                <Icon name="search" size={'1.3rem'} />
              </span>
            </div>
            <Field label="Namespace:">
              <MultiSelect
                filter
                style={{ width: '20rem' }}
                value={
                  (
                    ((report?.filters || {}).namespace ||
                      {}) as DataTableFilterMetaData
                  )?.value
                }
                options={Array.from(
                  new Set(
                    (Object.values(report?.data?.items || {}) || []).map(
                      (x) => x.namespace,
                    ),
                  ),
                )}
                onChange={onChangeNamespaces}
                showSelectAll={false}
                showClear={true}
                clearIcon={
                  <Icon
                    onClick={onClearAllNamespaces}
                    className={cx(styles.clearSelect)}
                    name="close"
                    size={'1.2rem'}
                  />
                }
                resetFilterOnHide={true}
                pt={{
                  closeButton: {
                    style: { display: 'none' },
                  },
                }}
                panelFooterTemplate={() => (
                  <div className={cx(styles.selectFooter)}>
                    <ClearAll onClick={onClearAllNamespaces} />
                  </div>
                )}
                virtualScrollerOptions={{ itemSize: 43 }}
                placeholder="Select..."
                className="w-full md:w-20rem"
              />
            </Field>
          </div>
          <FilterTags
            titles={filterTitles}
            onClearAll={onClearAllFilterHandler}
            onClear={onClearFilterHandler}
            filters={report?.filters || {}}
          />
        </>
      )}
      <h4 className={styles.count}>
        {!showSkeleton ? `${filteredCount || 0} objects found` : ''}
      </h4>

      <div
        style={{
          minHeight: 760,
          marginBottom: 10,
          position: 'relative',
        }}
      >
        <DataGrid
          skeletonTemplate={<SkeletonTemplateReportTable />}
          showSkeleton={showSkeleton}
          onValueChange={onValueDataGridChangeHandler}
          rows={visibleRows}
          onRowClick={onRowClickHandler}
          filters={report?.filters}
          onFilter={onFilterHandler}
          onSort={onSortHandler}
          sortField={report?.sortBy?.sortField}
          sortOrder={report?.sortBy?.sortOrder}
          data={items || []}
          columns={reportColumns}
        />
        <div className={cx(styles.footer)}>{showMoreButton}</div>
      </div>
    </div>
  );
};

const HeaderStatus = () => {
  const { state } = usePageContext<StateProps>();
  const { executionId } = useParams();
  const { getReport } = useCurrentReport();
  const report = getReport(state?.reportId || executionId);
  const status = report?.data?.status;
  if (status === 'NEW' || status === 'IN_PROGRESS') {
    return (
      <div className={cx(styles.headerReport, status?.toLowerCase())}>
        <h6>
          We are creating report for you. Please wait
          <LoadingDots />
        </h6>
      </div>
    );
  }
  if (status === 'ERROR') {
    return (
      <div className={cx(styles.headerReport, status?.toLowerCase())}>
        <h6 className={cx(styles[status?.toLowerCase() || 'error'])}>
          An error occurred while generating the report.
        </h6>
      </div>
    );
  }
  if (status === 'DONE') {
    return report?.data?.finishedAt ? (
      <div className={styles.createReport}>
        <h5 className={styles.label}>Created:</h5>
        <p className={styles.date}>{getDataTime(report?.data?.finishedAt)}</p>
      </div>
    ) : (
      <></>
    );
  }
  return <></>;
};

export default function RouterConfig({
  prefix,
  showHomePageInBreadcrumbs,
}: {
  prefix: string | undefined;
  showHomePageInBreadcrumbs?: boolean;
}) {
  return {
    title: 'Report',
    showHomePageInBreadcrumbs,
    headerRightTemplate: <HeaderStatus />,
    pageStyle: 'summary',
    route: {
      path: `${prefix}/:executionId`,
      Component: Report,
    },
  } as RoutePageData;
}
