/* eslint no-case-declarations: "off"*/

export * from './normalized-data.utils';

export function isNullOrUndefined<T>(value: T) {
  return value === null || value === undefined;
}

export const getAssetsPath = (fileName: string, extension = 'svg') =>
  `/assets/${fileName}.${extension}`;

export function isEqual<T>(value: T, other: T): boolean {
  // Check for simple equality
  if (value === other) {
    return true;
  }

  // Check for null or undefined
  if (value == null || other == null) {
    return false;
  }

  // Check for type equality
  if (typeof value !== typeof other) {
    return false;
  }

  // Check for object or array
  if (typeof value === 'object') {
    const valueKeys = Object.keys(value);
    const otherKeys = Object.keys(other);

    // Check for equal number of keys
    if (valueKeys.length !== otherKeys.length) {
      return false;
    }

    // Compare each key and value
    for (const key of valueKeys) {
      if (
        !otherKeys.includes(key) ||
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        !isEqual((value as any)[key], (other as any)[key])
      ) {
        return false;
      }
    }

    return true;
  }

  // Values are not objects or arrays and have already been checked for equality
  return false;
}

export function toCapitalize(inputString: string): string {
  if (inputString?.length === 0) {
    return inputString;
  }

  const words: string[] = inputString?.toLowerCase().split(' ');

  const capitalizedWords: string[] = (words || [])?.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  const capitalizedString: string = capitalizedWords.join(' ');

  return capitalizedString;
}

export const isEmptyString = (s: string | null | undefined) => {
  return typeof s === 'string' && s?.trim() === '';
};

export function isEmpty<T>(value: T) {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === 'object') {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else if (value instanceof Map || value instanceof Set) {
      return value.size === 0;
    } else {
      return Object.keys(value).length === 0;
    }
  }

  return value === '';
}

function copy(text: string) {
  return new Promise((resolve, reject) => {
    if (navigator && navigator?.clipboard && navigator?.permissions) {
      const type = 'text/plain';
      const blob = new Blob([text], { type });
      const data = [new ClipboardItem({ [type]: blob })];
      navigator.permissions
        .query({ name: 'clipboard-write' as PermissionName })
        .then((permission) => {
          if (permission.state === 'granted' || permission.state === 'prompt') {
            navigator.clipboard.write(data).then(resolve, reject).catch(reject);
          } else {
            reject(new Error('Permission not granted!'));
          }
        });
    } else if (
      document.queryCommandSupported &&
      document.queryCommandSupported('copy')
    ) {
      const textarea = document.createElement('textarea');
      textarea.textContent = text;
      textarea.style.position = 'fixed';
      textarea.style.width = '2em';
      textarea.style.height = '2em';
      textarea.style.padding = '0';
      textarea.style.border = 'none';
      textarea.style.outline = 'none';
      textarea.style.boxShadow = 'none';
      textarea.style.background = 'transparent';
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      try {
        document.execCommand('copy');
        document.body.removeChild(textarea);
        resolve(true);
      } catch (e) {
        document.body.removeChild(textarea);
        reject(e);
      }
    } else {
      reject(
        new Error('None of copying methods are supported by this browser!'),
      );
    }
  });
}

export const copyToClipboard =
  (
    text: string,
    success?: () => void,
    failed?: (error: React.ReactNode) => void,
  ) =>
  (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    copy(text)
      .then(() => {
        if (typeof success === 'function') {
          success();
        }
      })
      .catch((error) => {
        if (typeof failed === 'function') {
          failed(error);
        }
      });
  };

export function normalizeBoolean(
  value: string | boolean | undefined | null,
): boolean {
  if (typeof value === 'boolean') {
    return value;
  } else if (typeof value === 'string') {
    return value?.toLowerCase() === 'true';
  } else {
    throw new Error(
      'Unsupported value type. Only string and boolean are supported.',
    );
  }
}

export function toUnderscore(text: string): string {
  return text
    .replace(/([a-z\d])([A-Z])/g, '$1_$2')
    .replace(/\s+/g, '_')
    .toLowerCase();
}

export function djb2Hash(input: string): number {
  let hash = 5381;
  for (let i = 0; i < input.length; i++) {
    hash = (hash * 33) ^ input.charCodeAt(i);
  }
  return hash >>> 0;
}
