export const DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_TIME_FORMAT = 'yyyy-MM-dd - hh:mm a';

export const diagnosticCommand1 =
'curl -G \'http://store-backend-prometheus-server:9090/api/v1/query\' \
--data-urlencode \'query=kubernetes_events_total{event_kind="Deployment",involved_object_name="store-front",namespace="store-webapp"}\' | \
jq \'.data.result[]\'';

export const diagnosticCommand2 =
"curl -G 'http://store-backend-loki-server:3100/loki/api/v1/query_range' \
--data-urlencode 'query={namespace=\"store-webapp\", pod=\"store-front-6966f8c8b-slmtj\"}' \
--data-urlencode 'start=2024-10-08T00:00:00Z' \
--data-urlencode 'end=2024-10-08T23:59:59Z' \
--data-urlencode 'limit=1000' | \
jq '.data.result[].values[][1]'";

