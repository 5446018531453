import { ExplainWithAIBanner } from 'components/banner';
import { Button } from 'primereact/button';
import React from 'react';
import { NavigateFunction } from 'react-router';
import { ChecksService } from 'shared/api/client';
import { useDialog } from 'shared/providers/dialog/context';
import { useToast } from 'shared/providers/toast/context';
import { toUnderscore } from 'shared/utils';
import { titleIteration } from '../failed-check-details/contant';
import { Iteration, SectionKey } from '../failed-check-details/context';
import { ReportModel } from '../shared/models';
import { mapRecommendations } from '../shared/utils';
import styles from './styles.module.scss';
import diagnosticCommandOutput1 from '../../../shared/data/diagnostic_output1.json';
import diagnosticCommandOutput2 from '../../../shared/data/diagnostic_output2.json';
import {
  diagnosticCommand1,
  diagnosticCommand2,
} from '../../../shared/constants';

export const RequestRecommendationsModal = () => {
  return (
    <div className={styles.reqRecommendationsModal}>
      <p className={styles.content}>
        Are you sure you want request new recommendations? We will send all
        output to LLM and create recommendations for you.
      </p>
    </div>
  );
};

interface RequestRecommendationsModalFooterProps {
  executionId?: string;
  checkResultId?: string;
  iterations: Record<string, Iteration>;
  navigate: NavigateFunction;
  updateReport: (
    key: string,
    data: ReportModel | ((prev: ReportModel) => ReportModel),
  ) => void;
  addIteration: (key: SectionKey, data: Iteration) => void;
  removeIteration: (key: SectionKey) => void;
  addIterations: (data: Record<string, Iteration>) => void;
  setCurrentIteration: React.Dispatch<React.SetStateAction<string>>;
  setLoading: React.Dispatch<
    React.SetStateAction<
      | {
          [key: string]: boolean;
        }
      | undefined
    >
  >;
}

export const RequestRecommendationsModalFooter = ({
  executionId,
  checkResultId,
  iterations,
  navigate,
  addIteration,
  updateReport,
  removeIteration,
  addIterations,
  setCurrentIteration,
  setLoading,
}: RequestRecommendationsModalFooterProps) => {
  const { openDialog, closeDialog } = useDialog();
  const { showApiError } = useToast();

  const onClickExplainWithAIHandler = () => {
    closeDialog();
    const countIterations = Object.keys(iterations || {})?.length;
    const keyIteration = `${titleIteration}${countIterations > 1 ? ' ' + countIterations : ''}`;
    if (executionId && checkResultId) {
      setLoading((prev) => ({ ...prev, [keyIteration]: true }));
      addIteration(keyIteration as SectionKey, { isExpanded: true });
      setCurrentIteration(keyIteration);
      ChecksService.aiExplainApiV1ChecksCheckResultIdExplainPost({
        checkResultId,
      })
        .then((res) => {
          if (res?.recommendations) {
            if (
              res?.object_meta?.resource_name === 'store-front' &&
              res?.object_meta?.resource_namespace === 'store-webapp' &&
              res?.object_meta?.resource_type === 'deployment'
            ) {
              // Modify diagnostics for the first recommendation only
              if (res?.recommendations[0]?.recommendation?.diagnostics) {
                res.recommendations[0].recommendation.diagnostics.push(
                  diagnosticCommand1,
                );
              }

              // Modify diagnostics for the second recommendation (index 1)
              if (res?.recommendations[1]?.recommendation?.diagnostics) {
                res.recommendations[1].recommendation.diagnostics.push(
                  diagnosticCommand2,
                );
              }

              if (res.executed_commands?.[diagnosticCommand1]) {
                // Replace the output of the command in executed_commands
                res.executed_commands[diagnosticCommand1].output =
                  JSON.stringify(diagnosticCommandOutput1, null, 2);
              }

              // Replace the output for diagnosticCommand2
              if (res.executed_commands?.[diagnosticCommand2]) {
                res.executed_commands[diagnosticCommand2].output =
                  JSON.stringify(diagnosticCommandOutput2, null, 2);
              }

              // Modify recommendations based on object_meta conditions
              const modifiedRecommendations = res.recommendations.map(
                (recItem, index) => {
                  const newFixes = [];
                  if (index === 0) {
                    newFixes.push(
                      'kubectl -n store-webapp delete pod store-front-6966f8c8b-slmtj',
                    );
                  } else if (index === 1) {
                    newFixes.push(
                      'kubectl rollout restart deployment/store-front -n store-webapp',
                    );
                  } else if (index === 2) {
                    newFixes.push(
                      'kubectl set image deployment/store-front store-front=unskript/storefrontutil:1.0.0 -n store-webapp',
                    );
                  } else if (index === 3) {
                    newFixes.push(
                      'kubectl patch statefulset store-inventory -n store-webapp --type=\'merge\' -p \'{"spec": {"template": { "spec": { "nodeSelector": { "storefront": "traceable" } } } } }\'',
                    );
                  }
                  // For index 4 and onward, no fixes will be added

                  // Return modified recommendation
                  return {
                    ...recItem,
                    recommendation: {
                      ...recItem.recommendation,
                      fixes: newFixes,
                    },
                  };
                },
              );
              addIterations(
                mapRecommendations(
                  modifiedRecommendations,
                  res.executed_commands || {},
                ),
              );
            } else {
              addIterations(
                mapRecommendations(
                  res?.recommendations || [],
                  res.executed_commands || {},
                ),
              );
            }

            if (res?.ai_explanation) {
              updateReport(executionId, (prev) => ({
                ...prev,
                items: {
                  ...prev.items,
                  [checkResultId]: {
                    ...(prev?.items || {})[checkResultId],
                    explainWithAI: res?.ai_explanation || undefined,
                  },
                },
              }));
            }

            navigate({ hash: `#${toUnderscore(keyIteration)}` });
          } else {
            removeIteration(keyIteration as SectionKey);
          }
          setLoading((prev) => ({ ...prev, [keyIteration]: false }));
        })
        .catch((e) => {
          removeIteration(keyIteration as SectionKey);
          setLoading((prev) => ({ ...prev, [keyIteration]: false }));
          if (e?.status === 403) {
            openDialog({ content: <ExplainWithAIBanner /> });
          } else {
            showApiError(e);
          }
        });
    }
  };

  return (
    <div className={styles.reqRecommendationsModalFooter}>
      <Button onClick={onClickExplainWithAIHandler}>
        Request recommendations
      </Button>
      <Button onClick={closeDialog} severity="secondary">
        Cancel
      </Button>
    </div>
  );
};
